import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { lastValueFrom, map } from 'rxjs';
import { BaseApiResponse } from '../models/bases/base-api-response';
import { Session, SessionFiles, SessionModel } from '../models/session';
import { BaseQuery } from '../models/query/base-query';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    constructor(readonly _baseRequestService: BaseRequestService) {}

    getSessions(query?: BaseQuery) {
        return lastValueFrom(
            this._baseRequestService
                .getJSON<BaseApiResponse<Session>>('sessions', {
                    data: query,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    getPublicSessions(query: { date: string; organization: string }) {
        return lastValueFrom(
            this._baseRequestService
                .getJSON<BaseApiResponse<Session>>('sessions/public', {
                    data: query,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    getSessionById(id: string) {
        return lastValueFrom(
            this._baseRequestService
                .getJSON<BaseApiResponse<SessionModel>>('sessions/' + id, {
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    createPublicSession(data: SessionModel) {
        return lastValueFrom(
            this._baseRequestService
                .postJSON<BaseApiResponse<SessionModel>>('sessions/book', {
                    data: data,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    getOrganizers(query?: BaseQuery): Promise<BaseApiResponse<string[]>> {
        return lastValueFrom(
            this._baseRequestService
                .getJSON<BaseApiResponse<string[]>>('sessions/organizers', {
                    is_loading: true,
                    data: query,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    createSession(data: SessionModel) {
        return lastValueFrom(
            this._baseRequestService
                .postJSON<BaseApiResponse<SessionModel>>('sessions', {
                    data: data,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    updateSession(id: string, data: SessionModel) {
        return lastValueFrom(
            this._baseRequestService
                .patchJSON<BaseApiResponse<SessionModel>>('sessions/' + id, {
                    data: data,
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    deleteSession(id: string) {
        return lastValueFrom(
            this._baseRequestService
                .deleteJSON<BaseApiResponse<SessionModel>>('sessions/' + id, {
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }
    getPublicOrganizers(query?: BaseQuery): Promise<BaseApiResponse<string[]>> {
        return lastValueFrom(
            this._baseRequestService
                .getJSON<BaseApiResponse<string[]>>(
                    'sessions/organizers/public',
                    {
                        is_loading: true,
                        data: query,
                    }
                )
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    getParticipants(query?: BaseQuery): Promise<BaseApiResponse<string[]>> {
        return lastValueFrom(
            this._baseRequestService
                .getJSON<BaseApiResponse<string[]>>('sessions/participants', {
                    is_loading: true,
                    data: query,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    updateSessionFile(id: string, data: SessionFiles) {
        return lastValueFrom(
            this._baseRequestService
                .patchJSON<BaseApiResponse<SessionFiles>>(
                    'sessions/' + id + '/files',
                    {
                        data: data,
                        is_loading: true,
                    }
                )
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }
}
